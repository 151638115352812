import PlanningTool from '@/views/PlanningTool/PlanningTool.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component<PlanningToolVES>({
  components: {
    PlanningTool,
  },
})
export default class PlanningToolVES extends Vue {
  public $pageTitle = 'Plannningtool VES';
}
